:root {
    --purple_100: #e1c7eb;
    --gray_400: #c0b9c2;
    --gray_800: #504953;
    --light_blue_700: #0b98d4;
    --teal_50: #e4f2ef;
    --purple_900_01: #582775;
    --teal_200: #7ccfc0;
    --teal_100: #b2e4db;
    --teal_500: #16977f;
    --teal_800: #006d5a;
    --black_900: #000000;
    --purple_50_01: #f3edf6;
    --purple_900: #580378;
    --teal_50_01: #e3f1ef;
    --teal_50_02: #e4f3f0;
    --white_A700: #ffffff;
    --purple_600: #83419c;
    --purple_50: #f3edf5;
    --purple_300: #b087bf;
    --red_72: #72214D;
    --blue_1D: #1DB3EB;
}